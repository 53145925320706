import * as React from "react";
import BaseButton from "../components/BaseButton";
import { Link } from "gatsby";

import Layout from "../components/Layout";

const IndexPage = (): React.ReactElement => (
  <Layout>
    <div className="flex justify-center items-center min-h-screen flex-col bg-black text-white">
      <h1>Page not found.</h1>
      <Link to="/"><BaseButton>Go home.</BaseButton></Link>
    </div>
  </Layout>
);

export default IndexPage;
